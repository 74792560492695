import React from 'react'

const Logo = ({ color }) => (
  <svg style={{ fill: color || '#000' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 930 500.8">
    <g>
      <path d="M200.7 476.4h-23.3c-8.2 0-10.8-2.8-10.8-11.3v-13.6h28.7c5.6 0 9.3-3.7 9.3-9.2v-3.7c0-5.5-3.7-9.2-9.3-9.2h-28.7v-10.7c0-8.5 2.8-11.5 10.8-11.5h22.7c5.5 0 9.2-3.7 9.2-9.3v-3.8c0-5.5-3.7-9.2-9.2-9.2h-23c-23.3 0-36.6 11.7-36.6 32v49.9c0 20 13.6 31.8 36.6 31.8h23.6c5.5 0 9.3-3.7 9.3-9.2v-3.8c0-5.5-3.8-9.2-9.3-9.2zM295.6 394.1c0-5.5-3.7-9.2-9.2-9.2h-58.9c-5.5 0-9.2 3.7-9.2 9.2v3.8c0 5.6 3.7 9.3 9.2 9.3H244v82.3c0 5.5 3.7 9.2 9.3 9.2h7.5c5.5 0 9.3-3.7 9.3-9.2v-82.3h16.4c5.5 0 9.2-3.7 9.2-9.3zM367.3 403.5c-2.6-10-10.6-20.7-25.1-20.7-12.6 0-22 7.8-25.4 20.7l-22 84.6a8.9 8.9 0 0 0 1.4 7.7 7.7 7.7 0 0 0 6.2 2.9h8.1c5.2 0 8.7-3 10.1-9l4.8-19.3h33.5l5 19.5c1.3 5.4 5.3 8.8 10.4 8.8h7.6a8 8 0 0 0 6.4-3 8.7 8.7 0 0 0 1.4-7.5zm-26.2 3.5c.3-1.4.8-1.4 1-1.4.8 0 1 .9 1.2 1.5l10.2 41.3h-22.7zM439.3 384.9h-24.6c-5.5 0-9.2 3.7-9.2 9.2v95.4c0 5.5 3.7 9.2 9.2 9.2h7.6c5.5 0 9.3-3.7 9.3-9.2v-28.8h7.6c25 0 41.8-15.2 41.8-37.9 0-18.3-13.1-37.9-41.8-37.9zm14.4 37.9c0 7.6-4 15.8-15.2 15.8h-7v-31.4h6.8c9.7 0 15.4 5.8 15.4 15.6zM571.2 384.9h-7.6c-5.6 0-9.3 3.7-9.3 9.2v35.1h-33.6v-35c0-5.6-3.7-9.3-9.2-9.3h-7.6c-5.5 0-9.3 3.7-9.3 9.2v95.4c0 5.5 3.8 9.2 9.3 9.2h7.6c5.5 0 9.2-3.7 9.2-9.2v-38.3h33.6v38.3c0 5.5 3.7 9.2 9.3 9.2h7.6c5.5 0 9.2-3.7 9.2-9.2V394c0-5.5-3.7-9.2-9.2-9.2zM643.6 382.8c-28 0-46.2 19.2-46.2 48.9v20.2c0 29.7 18.2 48.8 46.2 48.8s46.1-19.1 46.1-48.8v-20.2c0-29.7-18.1-48.9-46.1-48.9zm-19.4 50.6c0-19 6.3-28.3 19.4-28.3 13.2 0 19.4 9 19.4 28.3v16.8c0 19.3-6.2 28.3-19.4 28.3-13 0-19.4-9.3-19.4-28.3zM781.5 384.9h-7.3c-5.5 0-9.2 3.7-9.2 9.2v39.6c0 4.2.2 9.2.6 14.2l-31.3-55.2c-3-5.2-7-7.8-12-7.8H716c-5.5 0-9.3 3.7-9.3 9.2v95.4c0 5.5 3.8 9.2 9.3 9.2h7.3c5.5 0 9.2-3.7 9.2-9.2v-39.6c0-4.2-.3-9.3-.7-14l31.2 55c2.9 5.1 7.1 7.8 12.2 7.8h6.3c5.6 0 9.3-3.7 9.3-9.2V394c0-5.5-3.8-9.2-9.3-9.2z"/>
      <rect x="811.5" y="384.9" width="26.1" height="113.8" rx="9.3" ry="9.3"/>
      <path d="M928.4 481.6a7.4 7.4 0 0 0-3.2-4.9 7 7 0 0 0-5.6-.6l-1.1.3a71.7 71.7 0 0 1-17.7 2c-13.7 0-19.3-6.3-19.3-21.9v-29.4c0-15.6 5.6-22 19.3-22a77.1 77.1 0 0 1 18 2.2 8 8 0 0 0 6.3-.7 7.2 7.2 0 0 0 3.3-4.7l1.4-6.5c1-4.5-1.1-8.1-5.5-9.3a105.8 105.8 0 0 0-26.7-3.3c-26.8 0-42.8 16-42.8 42.5v33c0 26.6 16 42.4 42.8 42.4a106.6 106.6 0 0 0 26.7-3.2c4-1 6.4-5 5.5-9.4zM10 406l-1 7.3a119.5 119.5 0 0 1 3.4 28A118.5 118.5 0 0 1 .3 493a7.5 7.5 0 0 0 1.2 2.6 116.4 116.4 0 0 0 13.7-53.8 117.5 117.5 0 0 0-5.2-35.8z"/>
      <path d="M8.8 415.4l-1.2 10.1A121 121 0 0 1 8.7 441a119.2 119.2 0 0 1-8 42.8L.1 489a11.4 11.4 0 0 0 0 2.6 117.1 117.1 0 0 0 11.6-50.2 119.2 119.2 0 0 0-2.9-25.9z"/>
      <path d="M11.9 392.5a14 14 0 0 0-.6 1.9l-.2 1.7a116.8 116.8 0 0 1 8.7 45.9 117 117 0 0 1-15.2 56 10 10 0 0 0 1.7.3 114.5 114.5 0 0 0 16-56 115.1 115.1 0 0 0-10.4-49.8z"/>
      <path d="M7.4 427.8L1 481.1A118.3 118.3 0 0 0 8.1 441a118.4 118.4 0 0 0-.8-13.2z"/>
      <path d="M10.9 398.1l-.7 5.8a117.8 117.8 0 0 1 5.9 37.8 117.8 117.8 0 0 1-13.9 54.6 7.5 7.5 0 0 0 1.5 1 115.5 115.5 0 0 0 15-55.3 116.3 116.3 0 0 0-7.8-43.9zM57.5 445.6l-.8-2.7-.1 2.7a117.5 117.5 0 0 1-10 39l.3.3a115.5 115.5 0 0 0 10.5-39.2zM52.9 445.2a117.6 117.6 0 0 1-8.6 36.5l.5.6a115.4 115.4 0 0 0 9.1-37q.5-5.2.4-10.5l-1.4-4.6a121.2 121.2 0 0 1 0 15z"/>
      <path d="M37.6 387a110 110 0 0 1 11.6 57.9 117.5 117.5 0 0 1-7 33l.6 1.2a116.4 116.4 0 0 0 7.6-34 116.5 116.5 0 0 0-1.4-28.4L42.2 394l-2-4.3a11 11 0 0 0-2.6-2.6zM17.5 385.6A113.3 113.3 0 0 1 30.7 440v-.3l1.8 6.7.2-3a111.3 111.3 0 0 0-13.6-58 10.6 10.6 0 0 0-1.6.3zM13.7 388.7a9.8 9.8 0 0 0-1.3 1.8 115.8 115.8 0 0 1 11 51.9 116.3 116.3 0 0 1-15.7 56.2h1.6a113.7 113.7 0 0 0 16.4-56 114 114 0 0 0-12-54z"/>
      <path d="M21.5 384.9H21a112.8 112.8 0 0 1 13.5 58.6l-.6 7.5 1.3 4.5c.5-3.9 1-7.8 1.1-11.8A110.3 110.3 0 0 0 22.6 385zM34.2 385.4a12.4 12.4 0 0 0-1.5-.3 110.4 110.4 0 0 1 12.8 59.4 117.8 117.8 0 0 1-5.2 28.7l.6 1.7a116.7 116.7 0 0 0 6-30.2c1.4-21.7-3.2-42.5-12.7-59.3zM59.5 452.3A115.9 115.9 0 0 1 49 487a1.7 1.7 0 0 0 .1.1A112.5 112.5 0 0 0 60 454zM61.7 460a113.5 113.5 0 0 1-10 28.8A110.3 110.3 0 0 0 62 461zM127.3 489l-11.2-94.8c-.7-5.6-4.7-9.3-10.2-9.3h-9.5c-5.4 0-9.5 3.2-11 8.8L63.7 467v-.2a110.5 110.5 0 0 1-9.2 23.3 24.9 24.9 0 0 0 9.3 1.8c12.1 0 20-6.7 24-20.4l6.8-23c.7-2.3 1.5-5 2.2-8.1l.6 7.8 4.1 41.4c.6 5.5 4.5 9 9.9 9h7.6a8.3 8.3 0 0 0 6.5-2.6 8.8 8.8 0 0 0 1.7-7z"/>
      <path d="M30.1 384.9h-1.5a111 111 0 0 1 13.2 59.3 118.2 118.2 0 0 1-3.4 22.7l.7 2.6a118 118 0 0 0 4.3-25.1c1.2-21.7-3.5-42.5-13.3-59.5zM28.7 461.3a113.5 113.5 0 0 1-14 37.4H16a110.3 110.3 0 0 0 11.4-24.9zM18.4 498.3a9.5 9.5 0 0 0 1.8-.5 106.2 106.2 0 0 0 6-11.4l.5-5a110 110 0 0 1-8.3 16.9z"/>
      <g>
        <path d="M16.1 386.5a9.7 9.7 0 0 0-1.5.9 114.5 114.5 0 0 1 12.5 55.3 115.7 115.7 0 0 1-16 56h1.6a113 113 0 0 0 16.6-55.6A112.7 112.7 0 0 0 16 386.5zM24.2 494.1a8.5 8.5 0 0 0 .7-1.3l-.7 1.3zM26.4 384.9h-1.7a111.8 111.8 0 0 1 13.4 59 117.6 117.6 0 0 1-1.8 15.7l1 3.5a119.1 119.1 0 0 0 2.5-19A109 109 0 0 0 26.4 385zM350.5 44.9l-1.9 15.6a249.5 249.5 0 0 1-18.8 169.7 16.3 16.3 0 0 0 2.6 5.4A248.2 248.2 0 0 0 361.6 121a250.3 250.3 0 0 0-11.1-76.1z"/>
        <path d="M348 65l-2.5 21.6a256.8 256.8 0 0 1 2.4 32.9 253.8 253.8 0 0 1-17.2 91.3l-1.3 10.8a24.6 24.6 0 0 0 0 5.4A249.8 249.8 0 0 0 354 120.2a253.6 253.6 0 0 0-6-55.2z"/>
        <path d="M354.5 16.2a26.6 26.6 0 0 0-1.1 4.1l-.4 3.6A250 250 0 0 1 339 241a20.1 20.1 0 0 0 3.6.7 243.8 243.8 0 0 0 11.8-225.4z"/>
        <path d="M345 91.5L331.3 205a251.8 251.8 0 0 0 15.1-85.4 255.5 255.5 0 0 0-1.6-28z"/>
        <path d="M352.5 28.2L351 40.5a251.4 251.4 0 0 1-17 196.8 14.8 14.8 0 0 0 3 2.2 248.2 248.2 0 0 0 15.5-211.3zM451.7 129.3l-1.7-5.7c0 1.9 0 3.8-.2 5.7a249.6 249.6 0 0 1-21.2 83.2l.7.8a245.5 245.5 0 0 0 22.4-83.7v-.3zM441.9 128.6a249.6 249.6 0 0 1-18.2 77.7l1 1.3a246.3 246.3 0 0 0 19.5-78.7c.6-7.6.8-15 .7-22.5l-2.8-9.8a256.3 256.3 0 0 1-.2 32z"/>
        <path d="M409.4 4.5a235.3 235.3 0 0 1 24.7 123.3 250.7 250.7 0 0 1-15 70.5l1.3 2.5a249.2 249.2 0 0 0 13.2-133l-14.4-48.6c-1.3-3-2.8-6.1-4.3-9.1a23.7 23.7 0 0 0-5.5-5.6zM366.6 1.6a241.4 241.4 0 0 1 28.2 115.6v-.7a280 280 0 0 0 3.8 14.4l.5-6.2c1.6-44.4-8.8-87.4-29-123.8a22.3 22.3 0 0 0-3.5.7zM358.5 8a20.4 20.4 0 0 0-2.8 3.9 249 249 0 0 1-10 230.3l1.3.2h2.2a243.4 243.4 0 0 0 9.3-234.3z"/>
        <path d="M375 0l-1 .2a240.7 240.7 0 0 1 28.7 124.6c-.2 5.4-.7 10.7-1.3 16l2.8 9.6A234.7 234.7 0 0 0 377.5 0z"/>
        <path d="M402 1a26.2 26.2 0 0 0-3.1-.5c19.5 35.7 30 79.5 27.3 126.6a251.7 251.7 0 0 1-11 61l1.1 3.6a248.3 248.3 0 0 0 12.8-64.2c3-46.2-6.7-90.5-27-126.4zM456 143.6a246.7 246.7 0 0 1-22.3 74c.1 0 .3 0 .4.2a239.7 239.7 0 0 0 22.8-70.6zM460.8 160.2a241.5 241.5 0 0 1-21.5 61.2 234.1 234.1 0 0 0 22-59zM600.5 221.7l-24-201.8C575 8 566.6 0 555 0h-20.3c-11.5 0-20.1 6.8-23.6 18.7L465.2 175l-.2-.4a235.7 235.7 0 0 1-19.6 49.6 53 53 0 0 0 19.8 3.7c26 0 42.7-14.1 51.2-43.3l14.5-48.9c1.6-5 3-10.7 4.6-17.3.4 6 .8 11.7 1.4 16.7l8.8 88c1.1 11.7 9.4 19.3 21 19.3h16.2c5.9 0 10.6-1.9 13.8-5.5s4.6-9 3.8-15.2z"/>
        <path d="M393.5 0h-3.3a236.8 236.8 0 0 1 28.2 126.3 251.3 251.3 0 0 1-7.3 48.4l1.6 5.5a250.4 250.4 0 0 0 9-53.4c2.6-46.1-7.5-90.5-28.2-126.8zM390.5 162.8a242.4 242.4 0 0 1-30 79.7h2.8a235 235 0 0 0 24.4-53zM368.4 241.6a21.1 21.1 0 0 0 4-1.1 226 226 0 0 0 12.6-24.2l1.1-10.8a236.4 236.4 0 0 1-17.7 36.1z"/>
        <path d="M363.6 3.5a21 21 0 0 0-3.2 2 244 244 0 0 1 26.7 117.8 246.5 246.5 0 0 1-34 119.2h3.2a241.6 241.6 0 0 0 7.3-239zM380.9 232.7a21.6 21.6 0 0 0 1.5-2.7l-1.5 2.7z"/>
        <path d="M385.5 0H382a238.3 238.3 0 0 1 28.6 125.5 254 254 0 0 1-4 33.6l2.2 7.6A253.2 253.2 0 0 0 414 126c2.3-45.6-8-89.6-28.6-126.1z"/>
      </g>
    </g>
  </svg>
)

export default Logo
