import _ from 'lodash'

const generic = [
  {
    name: 'Standing Person – AMS Acoustics',
    source: 'AMS Acoustics Data',
    reference: 'http://www.amsacoustics.co.uk/pdfs/IR-57-Absofanaverageperson.pdf',
    type: 'ams-acoustics-standing-person',
    quantity: 1,
    area: null, // m. sq.
    sabins: {
        63: 0,
       125: .05,
       250: .16,
       500: .25,
      1000: .58,
      2000: .86,
      4000: 1.03,
      8000: 1.29,
    },
  },
  {
    name: 'Standing Person – Leo. L. Beranek',
    source: 'Leo. L. Beranek',
    type: 'leo-beranek-standing-person',
    quantity: 1,
    sabins: {
        63: 0,
       125: .19,
       250: .33,
       500: .44,
      1000: .42,
      2000: .46,
      4000: .37,
      8000: 0,
    },
  },
  {
    name: 'Male Standing – Heinrich Kuttruff',
    source: 'Room Acoustics – Heinrich Kuttruff',
    type: 'heinrich-kuttruff-male-standing',
    quantity: 1,
    sabins: {
        63: 0,
       125: .12,
       250: .24,
       500: .59,
      1000: .98,
      2000: 1.13,
      4000: 1.12,
      8000: 0,
    },
  },
]

const knaufMarkant = [
  {
    name: 'Quadril Tile, 12x12mm Square Perf., No wool, D-200mm',
    type: 'knauf-markant-quadril-12x12-sq-hole-no-wool-d200',
    width: null,
    height: null,
    price: 3000 / 485,
    coefficients: {
        63: .30,
       125: .45,
       250: .60,
       500: .70,
      1000: .65,
      2000: .60,
      4000: .45,
      8000: .35,
    },
  },
  {
    name: 'Quadril Tile, 12x12mm Square Perf., No wool, D-65mm',
    type: 'knauf-markant-quadril-12x12-sq-hole-no-wool-d65',
    width: null,
    height: null,
    price: 3000 / 485,
    coefficients: {
        63: .05,
       125: .15,
       250: .30,
       500: .60,
      1000: .80,
      2000: .60,
      4000: .40,
      8000: .20,
    },
  },
  {
    name: 'Quadril Tile, 12x12mm Square Perf., 50mm wool, D-200mm',
    type: 'knauf-markant-quadril-12x12-sq-hole-50mm-wool-d200',
    width: null,
    height: null,
    price: 3000 / 485 + 725 / 485,
    coefficients: {
        63: .25,
       125: .55,
       250: .80,
       500: .85,
      1000: .70,
      2000: .70,
      4000: .55,
      8000: .40,
    },
  },
  {
    name: 'Quadril Tile, 12x12mm Square Perf., 50mm wool, D-65mm',
    type: 'knauf-markant-quadril-12x12-sq-hole-50mm-wool-d65',
    width: null,
    height: null,
    price: 3000 / 485 + 725 / 485,
    coefficients: {
        63: .15,
       125: .40,
       250: .65,
       500: .75,
      1000: .70,
      2000: .65,
      4000: .50,
      8000: .30,
    },
  },
]

const armourcoat = [
  {
    name: 'Acoustic Plaster, 50mm System',
    type: 'armourcoat-acoustic-plaster-50mm-system',
    width: null,
    height: null,
    coefficients: {
        63: .07,
       125: .32,
       250: .79,
       500: .91,
      1000: .96,
      2000: .95,
      4000: .83,
      8000: .70,
    },
  },
  {
    name: 'Acoustic Plaster, 30mm System',
    type: 'armourcoat-acoustic-plaster-30mm-system',
    width: null,
    height: null,
    coefficients: {
        63: .03,
       125: .13,
       250: .44,
       500: .84,
      1000: .97,
      2000: .91,
      4000: .80,
      8000: .65,
    },
  },
]

const ecophon = [
  {
    name: 'Ecophon Master™ Matrix, 40mm, 255mm o.d.s',
    type: 'ecophon-master-matrix-40mm-255mmODS-system',
    width: null,
    height: null,
    coefficients: {
        63: .20,
       125: .55,
       250: .85,
       500: .85,
      1000: 1.0,
      2000: 1.0,
      4000: 1.0,
      8000: 1.0,
    },
  },
  {
    name: 'Ecophon Master™ Matrix, 40mm, 300mm o.d.s',
    type: 'ecophon-master-matrix-40mm-300mmODS-system',
    width: null,
    height: null,
    coefficients: {
        63: .25,
       125: .60,
       250: .80,
       500: .90,
      1000: 1.0,
      2000: 1.0,
      4000: 1.0,
      8000: 1.0,
    },
  },
]

const theFoamFactory = [
  {
    name: '1" Pyramid Foam',
    type: 'the-foam-factory-acoustic-foam-pyramid-1-inch',
    width: null,
    height: null,
    coefficients: {
        63: .03,
       125: .14,
       250: .13,
       500: .31,
      1000: .43,
      2000: .46,
      4000: .54,
      8000: .65,
    },
  },
  {
    name: '2" Pyramid Foam',
    type: 'the-foam-factory-acoustic-foam-pyramid-2-inch',
    width: null,
    height: null,
    coefficients: {
        63: .05,
       125: .19,
       250: .22,
       500: .45,
      1000: .55,
      2000: .60,
      4000: .76,
      8000: .85,
    },
  },
  {
    name: '3" Pyramid Foam',
    type: 'the-foam-factory-acoustic-foam-pyramid-3-inch',
    width: null,
    height: null,
    coefficients: {
        63: .08,
       125: .24,
       250: .29,
       500: .55,
      1000: .56,
      2000: .61,
      4000: .85,
      8000: .90,
    },
  },
  {
    name: '4" Pyramid Foam',
    type: 'the-foam-factory-acoustic-foam-pyramid-4-inch',
    width: null,
    height: null,
    coefficients: {
        63: .10,
       125: .28,
       250: .39,
       500: .74,
      1000: .69,
      2000: .75,
      4000: .96,
      8000: .90,
    },
  },
  {
    name: '1" Wedge Foam',
    type: 'the-foam-factory-acoustic-foam-wedge-1-inch',
    width: null,
    height: null,
    coefficients: {
        63: .03,
       125: .14,
       250: .17,
       500: .36,
      1000: .47,
      2000: .51,
      4000: .61,
      8000: .68,
    },
  },
  {
    name: '2" Wedge Foam',
    type: 'the-foam-factory-acoustic-foam-wedge-2-inch',
    width: null,
    height: null,
    coefficients: {
        63: .04,
       125: .20,
       250: .29,
       500: .66,
      1000: .80,
      2000: .89,
      4000: 1.02,
      8000: 1.0,
    },
  },
  {
    name: '3" Wedge Foam',
    type: 'the-foam-factory-acoustic-foam-wedge-3-inch',
    width: null,
    height: null,
    coefficients: {
        63: .07,
       125: .25,
       250: .47,
       500: .83,
      1000: .82,
      2000: .92,
      4000: 1.04,
      8000: 1.0,
    },
  },
  {
    name: '3" Wedge Foam | 24" x 24", 12 Pack',
    type: 'the-foam-factory-acoustic-foam-wedge-3x24x24',
    quantity: 1,
    price: 86.99,
    area: 4.46,
    sabins: {
        63: 0.4,
       125: 1.12,
       250: 2.1,
       500: 3.7,
      1000: 3.65,
      2000: 4.1,
      4000: 4.64,
      8000: 4.0,
    },
  },
  {
    name: '4" Wedge Foam',
    type: 'the-foam-factory-acoustic-foam-wedge-4-inch',
    width: null,
    height: null,
    coefficients: {
        63: .14,
       125: .39,
       250: .61,
       500: .91,
      1000: .79,
      2000: .95,
      4000: 1.03,
      8000: 1.0,
    },
  },
  {
    name: 'Corner Bass Absorber',
    type: 'the-foam-factory-corner-bass-absorber',
    width: 30,
    height: 60,
    coefficients: {
        63: 0.50,
       125: 1.18,
       250: 1.27,
       500: 1.26,
      1000: 1.19,
      2000: 1.16,
      4000: 1.16,
      8000: 1.10,
    },
  },
  {
    name: 'Corner Bass Absorber | 12" x 12" x 24", 8 pack',
    type: 'the-foam-factory-corner-bass-absorber-12x12x24-8pack',
    price: 98.99,
    area: 1.475,
    sabins: {
        63: _.round(0.50 * 1.475, 2),
       125: _.round(1.18 * 1.475, 2),
       250: _.round(1.27 * 1.475, 2),
       500: _.round(1.26 * 1.475, 2),
      1000: _.round(1.19 * 1.475, 2),
      2000: _.round(1.16 * 1.475, 2),
      4000: _.round(1.16 * 1.475, 2),
      8000: _.round(1.10 * 1.475, 2),
    },
  },
  {
    name: 'Corner Bass Absorber | 12" x 12" x 24", 8 pack | non-touching inst.',
    type: 'the-foam-factory-corner-bass-absorber-12x12x24-8pack-non-touch',
    price: 98.99,
    area: 2.86,
    sabins: {
        63: _.round(0.50 * 2.86, 2),
       125: _.round(1.18 * 2.86, 2),
       250: _.round(1.27 * 2.86, 2),
       500: _.round(1.26 * 2.86, 2),
      1000: _.round(1.19 * 2.86, 2),
      2000: _.round(1.16 * 2.86, 2),
      4000: _.round(1.16 * 2.86, 2),
      8000: _.round(1.10 * 2.86, 2),
    },
  },
]

const audimute = [
  {
    name: '4" Bass Trap (Eco-c-Tex)',
    type: 'audimute-acoustical-absorption-bass-trap-4-inch',
    width: 60,
    height: 60,
    price: 85.00,
    coefficients: {
        63: 0.35,
       125: 0.94,
       250: 1.29,
       500: 1.19,
      1000: 1.05,
      2000: 1.06,
      4000: 1.09,
      8000: 1.00,
    },
  }
]

export default {
  'Generic': generic,
  'KNAUF Markant': knaufMarkant,
  'ARMOURCOAT': armourcoat,
  'Ecophon': ecophon,
  'The Foam Factory': theFoamFactory,
  'Audimute': audimute,
}
